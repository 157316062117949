import { useEffect, useId, useRef, useState } from "react"
import { Dropdown, Form } from "react-bootstrap"
import { LoadingComponent } from "../Loading"
import { useTranslation } from "react-i18next"
import { BsSearch } from "react-icons/bs"
import InfiniteScroll from "react-infinite-scroll-component"
import { TableEmptyState } from "../TableEmptyState"

export function DropdownInput<T>(props: DropdownInputProps<T>) {
  const { t } = useTranslation()
  const {
    onChange,
    onScroll,
    count,
    options,
    renderTitle = () => "",
    renderName,
    dropdownButtonContent = renderName,
    renderOption = renderName,
    isLoading,
    required,
    onClick,
    value,
    disabled,
    customFeedbackMessage,
    containerClassName,
    namePrefix = "",
    select,
    id,
    dropdownTitle,
    textColor = "text-dark",
    bgColor = "bg-white",
    emptyState = <TableEmptyState />,
    deselectable,
    customDropdownClassName,
    allOption,
    isInvalid,
    customDisplay,
  } = props

  const [search, setSearch] = useState<string>("")
  const [invalidSelection, setInvalidSelection] = useState(false)
  const scrollId = useId()

  const inputRef = useRef<HTMLInputElement | null>(null)
  const toggleRef = useRef<HTMLButtonElement | null>(null)

  const handleScroll = async () => onScroll && onScroll()

  const handleClick = (newSelected: T) => {
    setInvalidSelection(false)
    inputRef.current?.setCustomValidity("")
    if (deselectable) {
      const newSelectedValue =
        JSON.stringify(newSelected) !== JSON.stringify(value)
          ? newSelected
          : undefined
      onClick(newSelectedValue)
    } else onClick(newSelected)
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    onChange && onChange(e)
    required && inputRef.current?.setCustomValidity("invalid")
  }

  function showValue() {
    const display = select
      ? t("Global.actions.select")
      : t("Global.attributes.all")

    return (
      customDisplay ||
      (value ? (
        <>
          {namePrefix} {dropdownButtonContent(value)}{" "}
        </>
      ) : (
        display
      ))
    )
  }

  function showLoadingAndEmptyState() {
    return isLoading ? (
      <Dropdown.Item
        className=" d-flex justify-content-center align-items-center"
        data-testid={`${id}FilterLoading`}
      >
        <LoadingComponent />
      </Dropdown.Item>
    ) : (
      (!options || options?.length === 0) && (
        <Dropdown.Header className="text-dark">{emptyState}</Dropdown.Header>
      )
    )
  }

  useEffect(() => {
    if (inputRef.current?.id) {
      const label = document.querySelector(
        `label[for="${inputRef.current.id}"]`,
      )

      const handleClick = () => toggleRef.current?.click()
      label?.addEventListener("click", handleClick)
      return () => label?.removeEventListener("click", handleClick)
    }
  }, [inputRef.current?.id])

  return (
    <div className={containerClassName ?? "d-flex flex-column w-100"}>
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-secondary"
          className={`dropdown-input-button ${textColor} ${bgColor} w-100 d-flex justify-content-between focus-ring form-select ${
            invalidSelection || isInvalid
              ? "focus-ring-danger border-danger"
              : "border"
          } ${customDropdownClassName}`}
          data-testid={`${id}FilterToggle`}
          disabled={disabled}
          title={dropdownTitle}
          ref={toggleRef}
        >
          <span className="text-truncate">{showValue()}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="w-100"
          renderOnMount
          data-testid={`${id}FilterContent`}
        >
          <Dropdown.Header hidden={!onChange}>
            <div className="w-100 position-relative d-flex">
              <Form.Control
                autoComplete="off"
                ref={inputRef}
                value={search}
                onChange={handleChange}
                required={required && !value}
                disabled={disabled}
                data-testid={`${id}FilterSearch`}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation()
                    e.preventDefault()
                    const newEvent = {
                      target: { value: e.currentTarget.value },
                    } as React.ChangeEvent<HTMLInputElement>
                    handleChange(newEvent)
                  }
                }}
                onInvalid={() => {
                  setInvalidSelection(true)
                }}
                className="dropdown-input-control"
              />

              <BsSearch
                size="1.3rem"
                className=" text-secondary position-absolute end-0 h-100 me-2 pe-1"
              />
            </div>
          </Dropdown.Header>
          <div
            style={{ maxHeight: "192px", overflowY: "auto" }}
            data-testid={`${id}FilterMenu`}
            id={scrollId}
          >
            {showLoadingAndEmptyState() || (
              <InfiniteScroll
                dataLength={options?.length || 0}
                next={handleScroll}
                hasMore={!!onScroll && !!count && options.length < count}
                loader={
                  <Dropdown.Item className="d-flex justify-content-center align-items-center">
                    <LoadingComponent />
                  </Dropdown.Item>
                }
                scrollableTarget={scrollId}
              >
                <>
                  {allOption && (
                    <Dropdown.Item
                      title={String(t("Global.attributes.all"))}
                      className={`${
                        !value ? "bg-success bg-opacity-25" : ""
                      } dropdown-input-item text-truncate`}
                      onClick={() => onClick(undefined)}
                      data-testid={`${id}FilterItemAll`}
                    >
                      {t("Global.attributes.all")}
                    </Dropdown.Item>
                  )}
                  {options?.map((item, index) => (
                    <Dropdown.Item
                      title={renderTitle(item)}
                      key={`${renderName(item)}: ${index}`}
                      className={`${
                        value &&
                        renderName(item) === renderName(value) &&
                        renderTitle(item) === renderTitle(value)
                          ? "bg-success bg-opacity-25"
                          : ""
                      } dropdown-input-item text-truncate`}
                      onClick={() => handleClick(item)}
                      data-testid={`${id}FilterItem${renderName(item)}`}
                    >
                      {renderOption(item)}
                    </Dropdown.Item>
                  ))}
                </>
              </InfiniteScroll>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {(invalidSelection || isInvalid) && (
        <small className="text-danger mt-1">
          {customFeedbackMessage ?? t("Global.alerts.requiredFieldInput")}
        </small>
      )}
    </div>
  )
}
