/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react"
import { Button, Form, InputGroup, Modal } from "react-bootstrap"
import { PriceInput } from "../../../../components/PriceInput"
import { WeightInput } from "../../../../utils/Format/WeightInput"
import { t } from "i18next"
import {
  createStockCheckin,
  listSolutions,
  updateStockCheckin,
} from "../../../../services/Stock.service"
import { SupplierList } from "../../../../services/customer.service"
import { BsFillPencilFill, BsPlus } from "react-icons/bs"
import { ActionButton } from "../../../../components/Buttons/ActionButton"
import { CustomAlert } from "../../../../components/Customs/CustomAlert"
import { validateEmails } from "../../../../utils/validateEmails"
import { DropdownInput } from "../../../../components/DropdownInput"
import { FilterMultiSelect } from "../../../../components/Filters/NewFilter/components/FilterTypes/FilterMultiSelect"
import { convertSolutions } from "../../../../utils/gooxxySolutionsEnum"
import { AxiosError } from "axios"
import SubcheckinDropdown from "./components/subcheckinDropdown"
import { useCountryConfigs } from "../../../../utils/hooks/useCountryConfigs"
import { Trans } from "react-i18next"
import { AdditionalInfoModal } from "../../../../components/Modal/AdditionalInfoModal/AdditionalInfoModal"
interface StockCheckinFormModalProps {
  isEdit?: boolean
  checkinValue?: string
  sentMailAt?: string | null
  grossWeight?: string
  netWeight?: string
  handleReloadTable?: (_value: boolean) => void
  handleFeedback: (
    show: boolean,
    message: string | JSX.Element,
    type: string,
  ) => void
  supplier?: string
  checkinId?: number
  productType?: string
  testid?: string
  gooxxySolutions?: Record<string, string[]>
  originCheckinId?: StockCheckin
  addSubcheckin?: boolean
  additionalInformation?: string
}

export function StockCheckinFormModal(props: StockCheckinFormModalProps) {
  const {
    currency,
    countryCode: [countryCode],
  } = useCountryConfigs()
  const kindOptions = ["raw_materials", "finished_goods"]
  const [show, setShow] = useState(false)
  const [messageError, setMessageError] = useState({ show: false, message: "" })
  const [sendEmail, setSendEmail] = useState<boolean>(false)
  const [subcheckin, setSubcheckin] = useState<boolean>(
    props.originCheckinId ? true : false,
  )
  const [emails, setEmails] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [priceInput, setPriceInput] = useState(props.checkinValue || "")
  const [suppliers, setSuppliers] = useState([] as SupplierFilterProps[])
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [originCheckin, setOriginCheckin] = useState<StockCheckin | undefined>(
    props.originCheckinId || undefined,
  )
  const [kind, setKind] = useState(props.isEdit ? props.productType : undefined)
  const [solution, setSolution] = useState<Record<string, string[]>>(
    props.isEdit && props.gooxxySolutions ? props.gooxxySolutions : {},
  )
  const [solutionOptions, setSolutionOptions] = useState(
    [] as Record<string, string>[],
  )

  const [formData, setFormData] = useState({
    supplier: props.supplier || "",
    checkinValue: props.checkinValue || "",
    grossWeight: props.grossWeight || "",
    netWeight: props.netWeight || "",
    additionalInformation: props.additionalInformation || "",
    spreadsheet: undefined as File | undefined,
    productType: props.productType || "",
    gooxxySolutionIds: props.gooxxySolutions || [],
    originCheckinId: props.originCheckinId || ({} as StockCheckin),
  })

  const [errors, setErrors] = useState({
    supplierError: false,
    spreadsheetError: false,
    checkinValueError: false,
    invalidFileFormat: false,
    emailError: false,
  })

  function convertKind(id: string | undefined) {
    const name =
      id === "raw_materials"
        ? t("StockCheckin.attributes.rawMaterial")
        : t("StockCheckin.attributes.finished")
    return name as string
  }

  useEffect(() => {
    if (props.isEdit) {
      setFormData({
        ...formData,
        checkinValue: props.checkinValue || "",
        grossWeight: props.grossWeight || "",
        netWeight: props.netWeight || "",
        productType: props.productType || "",
        gooxxySolutionIds: props.gooxxySolutions || [],
        additionalInformation: props.additionalInformation || "",
      })
    }
    if (show) {
      fetchSuppliers()
      fetchSolutions()
    }
  }, [props.isEdit, show])

  useEffect(() => {
    if (isFormSubmitted) {
      const isSupplierFilled = formData.supplier.trim() !== ""
      const isSpreadsheetFilled = formData.spreadsheet !== undefined
      const isCheckinValueFilled = formData.checkinValue.trim() !== ""

      if (
        (isSupplierFilled || !props.isEdit) &&
        isSpreadsheetFilled &&
        (isCheckinValueFilled || !props.isEdit)
      ) {
        false
      } else {
        true
      }
    }
  }, [
    isFormSubmitted,
    formData.supplier,
    formData.spreadsheet,
    props.isEdit,
    formData.checkinValue,
  ])

  useEffect(() => {
    if (priceInput?.length > 0 || Boolean(priceInput) || priceInput !== "0.0") {
      setErrors({
        supplierError: false,
        spreadsheetError: false,
        checkinValueError: false,
        invalidFileFormat: false,
        emailError: false,
      })
    }
  }, [priceInput])

  async function fetchSuppliers() {
    try {
      const response = await SupplierList()
      setSuppliers(response.suppliers)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))

    if (name === "supplier" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        supplierError: true,
      }))
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        supplierError: false,
      }))
    }

    if (props.isEdit && name === "checkinValue" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        checkinValueError: true,
      }))
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        checkinValueError: false,
      }))
    }
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))

    if (name === "supplier" && value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        supplierError: false,
      }))
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]

    if (selectedFile) {
      if (!selectedFile.name.match(/\.(xlsx|csv|xls|xml)$/i)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          spreadsheetError: true,
          invalidFileFormat: true,
        }))
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          spreadsheetError: false,
          invalidFileFormat: false,
        }))
      }

      setFormData((prevData) => ({
        ...prevData,
        spreadsheet: selectedFile,
      }))
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        invalidFileFormat: false,
      }))
    }
  }

  const handleWeightInputChange = (
    name: string,
    _weight: number | undefined,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: _weight !== undefined ? _weight.toString() : "",
    }))
  }

  const handleValidateEmails = () => {
    const response = validateEmails(emails)
    if (sendEmail) {
      if (!response) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          emailError: true,
        }))
        return false
      }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      emailError: false,
    }))

    return true
  }

  const createAndEditStockCheckin = async () => {
    setIsFormSubmitted(true)
    props.handleReloadTable && props.handleReloadTable(false)
    const isSupplierEmpty =
      !formData.supplier || formData.supplier === "undefined"
    const isSpreadsheetEmpty = !formData.spreadsheet
    const isCheckinValueInvalid =
      (props.isEdit && !priceInput) ||
      priceInput === "0.0" ||
      (props.isEdit && Number(priceInput) <= 0)

    const checkinValueError = !!isCheckinValueInvalid

    setErrors({
      supplierError: isSupplierEmpty,
      spreadsheetError: isSpreadsheetEmpty,
      checkinValueError,
      invalidFileFormat: false,
      emailError: false,
    })

    if (
      isSupplierEmpty ||
      (isSpreadsheetEmpty && !props.isEdit) ||
      isCheckinValueInvalid ||
      !handleValidateEmails()
    ) {
      setMessageError({
        show: true,
        message: t("Global.alerts.emptyFields"),
      })
      return
    }

    try {
      setIsLoading(true)

      const payload: StockCheckinPayload = {
        supplierId: formData.supplier,
        totalValue: priceInput,
        grossWeight: formData.grossWeight,
        netWeight: formData.netWeight,
        kind: formData.productType as "raw_materials" | "finished_goods",
        gooxxySolutionIds: solution.solutions,
        originCheckinId:
          subcheckin && originCheckin ? String(originCheckin?.id) : "",
        additionalInformation: formData.additionalInformation,
      }

      const cleanPayload: StockCheckinPayload = Object.fromEntries(
        Object.entries(payload).filter(
          ([_, value]) =>
            value !== null &&
            value !== undefined &&
            value !== "" &&
            value !== "null",
        ),
      )

      if (props.isEdit) {
        if (emails.trim() !== "") {
          const sendPayload = {
            ...cleanPayload,
            emailSendTo: emails.replace(" ", "").replace(",", ";"),
          }
          await updateStockCheckin(props.checkinId as number, sendPayload)
        } else {
          await updateStockCheckin(props.checkinId as number, cleanPayload)
        }
        props.handleFeedback(
          true,
          <Trans
            i18nKey="StockCheckin.alerts.update.success"
            values={{ code: props.checkinId }}
            components={{ b: <b /> }}
          />,
          "success",
        )
      } else {
        const response = await createStockCheckin({
          ...cleanPayload,
          file: formData.spreadsheet,
        })
        props.handleFeedback(
          true,
          <Trans
            i18nKey={"StockCheckin.alerts.created.success"}
            values={{ code: response.id }}
            components={{ b: <b /> }}
          />,
          "success",
        )
      }

      if (props.handleReloadTable) {
        await props.handleReloadTable(true)
      }
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const { response } = error
        typeof response?.data.messages === "string"
          ? setMessageError({
              show: true,
              message: response?.data.messages,
            })
          : setMessageError({
              show: true,
              message: response?.data.messages.join("<br>"),
            })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onClose = () => {
    setFormData({
      supplier: props.supplier || "",
      checkinValue: props.checkinValue || "",
      grossWeight: props.grossWeight || "",
      netWeight: props.netWeight || "",
      additionalInformation: "",
      spreadsheet: undefined,
      productType: props.productType || "",
      gooxxySolutionIds: props.gooxxySolutions || [],
      originCheckinId: props.originCheckinId || ({} as StockCheckin),
    })
    setKind("")
    setSolution({})
    setSendEmail(false)
    setEmails("")
    setPriceInput(props.checkinValue || "")
    setShow(false)

    setErrors({
      supplierError: false,
      spreadsheetError: false,
      checkinValueError: false,
      invalidFileFormat: false,
      emailError: false,
    })

    setMessageError({
      show: false,
      message: "",
    })

    setIsFormSubmitted(false)
    setIsLoading(false)
  }

  const open = () => setShow(true)

  function verifyNumber(data: string) {
    if (data === "null") {
      return false
    }
    const number = Number(data)
    const numberIsGreaterThanZero = number > 0

    return Boolean(numberIsGreaterThanZero)
  }

  function selectKind(selected: string) {
    setFormData((prevData) => ({
      ...prevData,
      productType: selected,
    }))
    setKind(selected)
  }

  const disableCheckbox =
    formData.supplier === "" || formData.supplier === "undefined"

  const htmlContent = t("StockCheckin.attributes.remember" as string)

  async function fetchSolutions() {
    try {
      const solutionOptions = await listSolutions()
      const optionsArray = solutionOptions.data.gooxxySolutions.map(
        (solution: gooxxySolutions) => {
          return {
            id: String(solution.id),
            code: solution.code as string,
          }
        },
      )
      setSolutionOptions(optionsArray as Record<string, string>[])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {props.addSubcheckin ? (
        <> </>
      ) : props.isEdit ? (
        <div
          className="ms-2 ps-1 my-1 py-1 d-flex align-items-center"
          onClick={open}
          data-testid={`stock-checkin-edit-button-${props.testid}`}
        >
          <BsFillPencilFill size={16} className="me-2" />
          <span>{t("StockCheckin.actions.identify/change") as string}</span>
        </div>
      ) : (
        <Button
          variant="success"
          onClick={open}
          data-testid="stock-checkin-register-button"
        >
          <span>{t("StockCheckin.attributes.registerCheckin")}</span>
        </Button>
      )}
      {props.addSubcheckin && !props.isEdit ? (
        <div
          onClick={open}
          className="d-flex align-items-center ms-2 py-1 my-1 pe-3"
          data-testid={`checkin-add-subcheckin-button-${props.testid}`}
        >
          <BsPlus className="p-0 ms-0 me-1" size={25} />
          <span className="ms">
            {t("StockCheckin.attributes.addSubcheckin")}
          </span>
        </div>
      ) : null}
      <div
        onKeyDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
        onMouseOver={(e) => e.stopPropagation()}
      >
        <Modal show={show} onHide={onClose} className="modal-lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {props.isEdit
                ? t("StockCheckin.actions.identify/change")
                : t("StockCheckin.attributes.registerCheckin")}
            </Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <CustomAlert
                onClose={() => {
                  setMessageError({ show: false, message: "" })
                }}
                variant="danger"
                show={messageError.show}
                dismissible={true}
                data-testid="stock-checkin-error-alert"
              >
                <span
                  dangerouslySetInnerHTML={{ __html: messageError.message }}
                />
              </CustomAlert>
              {!props.isEdit && (
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("StockCheckin.attributes.spreadsheet")}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="spreadsheet"
                    accept=".xls, .csv, .xlsx, .xml"
                    onChange={handleFileChange}
                    isInvalid={
                      errors.spreadsheetError ||
                      (formData.spreadsheet && errors.invalidFileFormat)
                    }
                    data-testid="spreadsheet"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.spreadsheet && errors.invalidFileFormat
                      ? t("StockCheckin.attributes.invalidFileFormat")
                      : errors.spreadsheetError &&
                        t("Global.alerts.requiredField")}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group className="mb-3">
                <Form.Label>{t("Global.attributes.category")}</Form.Label>
                <DropdownInput
                  select
                  value={kind ? kind : undefined}
                  options={kindOptions}
                  renderName={(item: string) => convertKind(item)}
                  renderTitle={(item: string) => convertKind(item)}
                  onClick={(selected: string) => selectKind(selected)}
                  id="productType"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FilterMultiSelect
                  options={solutionOptions}
                  filter={solution}
                  filterName="solutions"
                  labelName={
                    t("StockCheckin.attributes.solutionType") as string
                  }
                  setFilter={setSolution}
                  renderName={(item) =>
                    convertSolutions(item?.code ? item?.code : "-")
                  }
                  renderValue={(item) => item.id}
                  select
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Global.attributes.supplier.one")}{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="supplier"
                  value={formData.supplier}
                  onChange={handleSelectChange}
                  isInvalid={errors.supplierError}
                  data-testid="supplier"
                >
                  <option hidden>
                    {t("Global.attributes.supplierSelect")}
                  </option>
                  {suppliers.map((supplier: SupplierFilterProps) => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.supplierError && t("Global.alerts.requiredField")}
                </Form.Control.Feedback>
              </Form.Group>
              {!props.isEdit && (
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("StockCheckin.attributes.checkinValue")}
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">
                      {currency.code}
                    </InputGroup.Text>
                    <InputGroup.Text id="basic-addon2" className="rounded-0">
                      {currency.symbol}
                    </InputGroup.Text>
                    <PriceInput
                      name="checkinValue"
                      onChange={(value) => setPriceInput(String(value))}
                      value={Number(priceInput)}
                      datatestid="checkinValue"
                    />
                  </InputGroup>
                </Form.Group>
              )}
              {props.isEdit && (
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("StockCheckin.attributes.checkinValue")}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">
                      {currency.code}
                    </InputGroup.Text>
                    <InputGroup.Text id="basic-addon2" className="rounded-0">
                      {currency.symbol}
                    </InputGroup.Text>
                    <PriceInput
                      name="checkinValue"
                      onChange={(value) => setPriceInput(String(value))}
                      value={Number(priceInput)}
                      isInvalid={errors.checkinValueError}
                      datatestid="checkinValueEdit"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.checkinValueError &&
                        t("Global.alerts.requiredField")}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              )}
              <Form.Group className="mb-3">
                <Form.Label>{t("Global.attributes.grossWeight")}</Form.Label>
                <InputGroup>
                  <WeightInput
                    value={Number(formData.grossWeight)}
                    name="grossWeight"
                    onChange={(value) =>
                      handleWeightInputChange("grossWeight", value)
                    }
                    datatestid="grossWeight"
                  />
                  <InputGroup.Text>kg</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("Global.attributes.netWeight")}</Form.Label>
                <InputGroup>
                  <WeightInput
                    name="netWeight"
                    value={Number(formData.netWeight)}
                    onChange={(value) =>
                      handleWeightInputChange("netWeight", value)
                    }
                    datatestid="netWeight"
                  />
                  <InputGroup.Text>kg</InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <div>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label={t("StockCheckin.attributes.subcheckinCheck")}
                    checked={subcheckin}
                    onChange={(event) => setSubcheckin(event.target.checked)}
                    data-testid="subcheckinCheck"
                  />
                </Form.Group>
                {subcheckin && (
                  <SubcheckinDropdown
                    originCheckin={originCheckin}
                    setOriginCheckin={setOriginCheckin}
                    disabled={!subcheckin}
                    supplierId={formData.supplier}
                  />
                )}
              </div>

              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Global.attributes.additionalInformation")}{" "}
                  <span className="text-muted">
                    {" "}
                    {t("Global.attributes.optional")}{" "}
                  </span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="additionalInformation"
                  value={formData.additionalInformation}
                  onChange={handleInputChange}
                  placeholder={
                    t(
                      "StockCheckin.attributes.placeholderAdditionalInformation",
                    ) as string
                  }
                  data-testid="additionalInformationRegister"
                />
              </Form.Group>
              {props.isEdit && (
                <Form.Group className="form-label">
                  <Form.Check
                    type="checkbox"
                    disabled={disableCheckbox}
                    title={
                      disableCheckbox
                        ? t(
                            "StockCheckin.attributes.titleCheckbox" as string,
                          ) || ""
                        : ""
                    }
                    id="sendEmail"
                    label={t("StockCheckin.attributes.titleLabel" as string)}
                    checked={sendEmail}
                    onChange={(event) => setSendEmail(event.target.checked)}
                  />
                  {props.sentMailAt !== null && sendEmail && (
                    <span
                      className=" text-danger "
                      style={{ fontSize: "13px" }}
                    >
                      {t("StockCheckin.attributes.feedbackSendEmail" as string)}
                    </span>
                  )}
                </Form.Group>
              )}
              {sendEmail && (
                <Form.Group>
                  <Form.Control
                    type="email"
                    as="textarea"
                    rows={3}
                    name="emails"
                    value={emails}
                    onChange={(e) => setEmails(e.target.value)}
                    isInvalid={errors.emailError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.emailError &&
                      t("StockCheckin.attributes.invalidEmails" as string)}
                  </Form.Control.Feedback>
                  <Form.Label className="fst-italic text-secondary">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: htmlContent,
                      }}
                    />
                  </Form.Label>
                </Form.Group>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={onClose}>
                {t("Global.actions.cancel")}
              </Button>
              <Button
                type="button"
                variant={props.isEdit ? "primary" : "success"}
                id="botao-cadastrar"
                onClick={createAndEditStockCheckin}
                disabled={isLoading}
                data-testid="checkinRegister"
              >
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="visually-hidden">
                      {t("StockCheckin.attributes.loading")}
                    </span>
                  </div>
                ) : props.isEdit ? (
                  t("Global.actions.save")
                ) : (
                  t("Global.actions.register")
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  )
}
