export const validateEmails = (emails: string) => {
  const parseEmails = (emailString: string) => {
    return emailString
      .split(/[;,]/)
      .map((email: string) => email.trim())
      .filter((email: string) => email !== "")
  }

  const emailArray = parseEmails(emails)
  const isValid = emailArray.every((email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email),
  )

  if (!isValid) {
    return false
  }

  return true
}
