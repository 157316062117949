import { formatMoney } from "../../../utils/Format/formatMoney"
import {
  getCountryCode,
  getCurrency,
  getIntLocale,
} from "../../../utils/hooks/useCountryConfigs/utils"

interface MoneyProps {
  amount: number | undefined
  print?: boolean
  isNegative?: boolean
  isNotJSX?: boolean
  "data-testid"?: string
  withDecimals?: boolean
}

export function FormatMoney(props: MoneyProps) {
  const { print, amount, isNegative, isNotJSX, withDecimals } = props
  if (amount === undefined || Number.isNaN(amount)) {
    return "-"
  }

  const countryCode = getCountryCode()
  const locale = getIntLocale(countryCode)
  const currency = getCurrency(countryCode)

  const formatMoneyByCountry = formatMoney(
    amount,
    locale,
    currency,
    withDecimals,
  )

  const formattedMoney =
    amount < 0 && (isNegative === undefined || isNegative)
      ? `(-) ${formatMoneyByCountry.slice(1)}`
      : formatMoneyByCountry

  return isNotJSX ? (
    formattedMoney
  ) : (
    <span
      className={print ? "text-wrap" : ""}
      data-testid={props["data-testid"]}
    >
      {formattedMoney}
    </span>
  )
}
