import { useState } from "react"
import { setApiErrorMessages } from "../../utils/setApiErrorMessage"
import { PopoverCustom } from "../PopoverCustom"
import { Button, Dropdown, Form, Popover } from "react-bootstrap"
import { MultiErrorAlerts } from "../MultiErrorAlerts"
import { DropdownInput } from "../DropdownInput"
import { ButtonSubmit } from "../Buttons/ButtonSubmit"
import { useTranslation } from "react-i18next"
import { capitalizeText } from "../../utils/Format"
import { styled } from "styled-components"

const CustomBadge = styled.div<{ variant: string }>`
  background-color: ${({ variant }) => `var(--bs-${variant})`};
  border-radius: 0.375rem;
  padding: 0.125rem 0.3125rem;
  font-size: 0.875rem;
  color: white;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 0.25rem;

  &:hover {
    filter: brightness(0.85);
  }
`

export function ChangeStatusPopover<T extends string>(
  props: ChangeStatusPopoverProps<T>,
) {
  const { t } = useTranslation()
  const {
    inputOptionName,
    inputOptions,
    badgeName,
    contentName = badgeName,
    currentStatus,
    handleEdit,
    badgeMap,
    title,
    setSuccess,
    "data-testid": dataTestId,
    showOnlyDropdown,
  } = props

  const [show, setShow] = useState(false)
  const [isEditing, setIsEditing] = useState<boolean>()
  const [validated, setValidated] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [status, setStatus] = useState<T>(currentStatus)
  const {
    variant = "secondary",
    icon = null,
    className = "",
  } = badgeMap[currentStatus] || {}

  function handleEditClick() {
    setIsEditing(true)
  }

  function handleClose() {
    setShow(false)
    setIsEditing(undefined)
    setErrorMessages([])
    setIsLoading(undefined)
    setStatus(currentStatus)
  }

  async function handleCreateClick(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (event.currentTarget.checkValidity() && status) {
      setIsLoading(true)
      setErrorMessages([])
      try {
        await handleEdit(status)
      } catch (error) {
        setApiErrorMessages(error, setErrorMessages)
      } finally {
        setIsLoading(false)
      }
    } else {
      setValidated(true)
    }
  }

  if (showOnlyDropdown) {
    return (
      <Dropdown show={show} onToggle={() => setShow(!show)}>
        <Dropdown.Toggle as={CustomBadge} variant={variant}>
          {icon} {badgeName}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {inputOptions.map((option) => (
            <Dropdown.Item
              key={option}
              onClick={() => {
                setStatus(option)
                handleEdit(option)
                setShow(false)
              }}
            >
              {inputOptionName(option)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <PopoverCustom
      className={`d-inline-flex align-items-center gap-1 badge border-0 ${className}`}
      onShow={() => {
        setSuccess(false)
      }}
      data-testid={dataTestId}
      popoverClassName="w-100"
      onClose={handleClose}
      setShow={setShow}
      variant={variant}
      placement="top"
      show={show}
      popoverContent={
        <>
          <Popover.Header as="h4" data-testid={dataTestId + "-popover-title"}>
            {title}
          </Popover.Header>
          <Popover.Body
            className="d-flex flex-column gap-2 w-100"
            data-testid={dataTestId + "-popover-body"}
          >
            {isEditing ? (
              <Form
                onSubmit={handleCreateClick}
                validated={validated}
                noValidate
              >
                {errorMessages.length > 0 && (
                  <MultiErrorAlerts items={errorMessages} />
                )}
                <Form.Group className="mb-3">
                  <DropdownInput<T>
                    renderName={inputOptionName}
                    onClick={(nextStatus) => setStatus(nextStatus)}
                    id={dataTestId + "-status"}
                    options={inputOptions}
                    value={status}
                    required
                    select
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <ButtonSubmit
                    name={t("Global.actions.save")}
                    data-testid="submit-button"
                    disabled={isLoading}
                    loading={isLoading}
                    variant="primary"
                    type="submit"
                    size="sm"
                  />
                </div>
              </Form>
            ) : (
              <>
                <div>{contentName}</div>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={handleEditClick}
                    data-testid="edit-button"
                    size="sm"
                  >
                    {capitalizeText(t("Global.actions.edit"))}
                  </Button>
                </div>
              </>
            )}
          </Popover.Body>
        </>
      }
    >
      {icon}
      {badgeName}
    </PopoverCustom>
  )
}
