import { ButtonGroup, Dropdown } from "react-bootstrap"
import { t } from "i18next"
import { BsFileEarmarkArrowDownFill, BsFillTrashFill } from "react-icons/bs"
import { HiDotsVertical } from "react-icons/hi"
import { ActionButton } from "../../../../../../components/Buttons/ActionButton"
import { StockCheckinFormModal } from "../../../StockCheckinFormModal"
import { DeleteItem } from "../../../../../../components/Modal/DeleteItem"

interface CheckinTableActionButtonsProps {
  item: StockCheckin
  handleReloadTable: ((_value: boolean) => void) | undefined
  handleFeedback: (
    success: boolean,
    message: string | JSX.Element,
    type: string,
  ) => void
  handleDeleteCheckin: (checkinId: number) => void
  handleSolutions: (solutions: gooxxySolutions[]) => Record<string, string[]>
  loading: boolean
  success: boolean
  originCheckin?: StockCheckin
  checkins: StockCheckin[]
}

export default function CheckinTableActionButtons(
  props: CheckinTableActionButtonsProps,
) {
  const {
    item,
    handleReloadTable,
    handleFeedback,
    handleDeleteCheckin,
    handleSolutions,
    loading,
    success,
    originCheckin,
    checkins,
  } = props

  function findOriginCheckin(id: number) {
    return checkins.find((checkin) => checkin.id === id)
  }

  return (
    <ButtonGroup className="checkin-action-buttons">
      <ActionButton
        disabled={!item.fileUrl}
        title={t("Global.actions.download") as string}
        onClick={() => window.open(item.fileUrl, "_blank")}
        data-testid={`download-checkin-file-${item.totalValue}`}
        className="download-file-button"
      >
        <div className="pb-1 ">
          <BsFileEarmarkArrowDownFill className="mx-1" />
        </div>
      </ActionButton>
      <ActionButton className="m-0 p-0">
        <Dropdown className="m-0 p-0">
          <Dropdown.Toggle
            variant="outline-primary"
            data-testid={`checkin-row-dropdown-${item.totalValue}`}
            className="arrow-dropdown border-0 rounded-0"
            style={{ maxHeight: "30px" }}
          >
            <div className="m-0 px-0 pb-1">
              <HiDotsVertical className="mx-0 my-1 px-0 " size={13} />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className="w-100 p-0 m-0">
              <StockCheckinFormModal
                handleReloadTable={handleReloadTable}
                handleFeedback={handleFeedback}
                originCheckinId={findOriginCheckin(item.id)}
                addSubcheckin={true}
                testid={item.totalValue}
              />
            </Dropdown.Item>
            <Dropdown.Item className="w-100 p-0 m-0">
              <StockCheckinFormModal
                isEdit
                sentMailAt={item.sentMailAt}
                handleFeedback={handleFeedback}
                handleReloadTable={handleReloadTable}
                checkinValue={item.totalValue}
                checkinId={item.id}
                supplier={String(item.supplier?.id)}
                netWeight={String(item.netWeight)}
                grossWeight={String(item.grossWeight)}
                productType={item.kind}
                gooxxySolutions={handleSolutions(item.gooxxySolutions)}
                originCheckinId={originCheckin}
                additionalInformation={item.additionalInformation}
                testid={item.totalValue}
              />
            </Dropdown.Item>
            <Dropdown.Item className="w-100 m-0 p-0">
              <DeleteItem
                text={t("StockCheckin.alerts.remove.removeAlert") as string}
                onDelete={async () => handleDeleteCheckin(item.id)}
                loading={loading}
                success={success}
                testid={`${item.totalValue}`}
                useIcon={true}
                useTooltip={false}
                buttonVariant="light"
                buttonClassName="bg-transparent border-0 m-0 p-0 text-start w-100"
                customIcon={
                  <>
                    <div className="d-flex align-items-center fs-6 ms-2 ps-1 my-1">
                      <BsFillTrashFill size={17} className="me-2" />
                      <span style={{ color: "#212529" }}>
                        {t("Global.attributes.delete") as string}
                      </span>
                    </div>
                  </>
                }
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ActionButton>
    </ButtonGroup>
  )
}
