import { t } from "i18next"

interface CheckinTableHeadProps {
  isSubcheckin?: boolean
}

export default function CheckinTableHead(props: CheckinTableHeadProps) {
  const { isSubcheckin } = props

  return (
    <>
      <thead>
        <tr>
          <th className={`${isSubcheckin && "bg-light"}`}></th>
          <th
            data-testid="shipment-header"
            className={`col-2 ${isSubcheckin && "bg-light"}`}
          >
            {t("Global.attributes.shipment")}
          </th>
          <th className={`col-1 ${isSubcheckin && "bg-light"}`}>
            <abbr title={t("StockCheckin.attributes.titleN") as string}>
              Nº
            </abbr>
          </th>
          <th className={`col-2 ${isSubcheckin && "bg-light"}`}>
            {t("StockCheckin.attributes.submitter")}
          </th>
          <th className={`col-2 ${isSubcheckin && "bg-light"}`}>
            {t("Global.attributes.supplier.one")}
          </th>
          <th className={`col-2 ${isSubcheckin && "bg-light"}`}>GoBlack</th>
          <th className={`${isSubcheckin && "bg-light"}`}>
            {t("StockCheckin.attributes.solutionType")}
          </th>
          <th className={`text-end ${isSubcheckin && "bg-light"}`}>
            {t("Global.attributes.amount")}
          </th>
          <th className={`text-end ${isSubcheckin && "bg-light"}`}>
            {t("Global.attributes.status")}
          </th>
          <th className={`text-end ${isSubcheckin && "bg-light"}`}>
            {t("Global.actions.actions")}
          </th>
        </tr>
      </thead>
    </>
  )
}
