export function formatMoney(
  amount: number,
  location: Intl.Locale,
  currency: string,
  withDecimals: boolean = true,
) {
  return amount.toLocaleString(location, {
    style: "currency",
    currency,
    ...(currency === "COP" || !withDecimals
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : {}),
  })
}
